import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';
import { getSettingsPropsByStructure } from 'utils/parseHelpers';
import useScreenRecognition from 'hooks/useScreenRecognition';
import VideoPlayer from 'components/VideoBlock/VideoPlayer';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';
import VideoBlock from 'components/VideoBlock';
import VideoBlockInline from 'components/VideoBlockInline';

import './InfoSection.scss';
import './InfoSectionOverride.scss';

const InfoSection: FC<HomePageTypes.TInfoSectionProps> = ({
  title,
  image,
  imageAlt = '',
  description,
  link,
  imageAlign,
  video,
  commonSettings,
  ariaCTA,
  ctaLogo,
  ctaLogoAlt,
  returnsNotice,
  sectionBackground,
  ctaButtonNotice,
  ctaButtonType,
  isShowMobileTitleOnTop,
  videoInlineBlock,
}) => {
  const position = imageAlign?.length ? imageAlign[0] : null;
  const cta = link?.length ? link[0] : null;
  const ctaType = ctaButtonType?.[0] || 'link';
  const cx = classnames({
    'info-section': true,
    [`info-section--image-${position}`]: position,
    [`info-section--no-image`]: !image?.fallbackUrl,
    [`info-section--inline-video`]: videoInlineBlock?.length,
    [`info-section--background-${sectionBackground?.label}`]: sectionBackground?.label,
  });
  const videoSettings = getSettingsPropsByStructure(commonSettings, 'Video Modal');

  const { isTablet, isMobile } = useScreenRecognition();

  return (
    <div className={cx} data-testid="info-section" id="info-section">
      <Container fluid>
        <div className="info-section__wrap">
          {isShowMobileTitleOnTop && (isTablet || isMobile) ? (
            <DangerouslySetInnerHtml
              className="info-section__title info-section__title--mobile-top"
              element="h2"
              html={title}
            />
          ) : null}

          <div className="info-section__text">
            <div className="info-section__text-wrapper">
              {isShowMobileTitleOnTop && !isTablet && !isMobile ? (
                <DangerouslySetInnerHtml
                  className="info-section__title"
                  element="h2"
                  html={title}
                />
              ) : null}

              {!isShowMobileTitleOnTop ? (
                <DangerouslySetInnerHtml
                  className="info-section__title"
                  element="h2"
                  html={title}
                />
              ) : null}

              {description ? (
                <DangerouslySetInnerHtml className="info-section__description" html={description} />
              ) : null}

              {cta ? (
                <h3 className="info-section__cta">
                  <Button to={cta.url} ariaLabel={ariaCTA} variant={ctaType}>
                    {cta.name}
                    {ctaLogo?.fallbackUrl ? (
                      <GatsbyImage fluid={ctaLogo} alt={ctaLogoAlt} />
                    ) : (
                      <IconCustom icon="chevron-right" />
                    )}
                  </Button>
                  {returnsNotice ? (
                    <div>
                      <IconCustom icon="returns" />
                      <span className="info-section__buy-returns">{returnsNotice}</span>
                    </div>
                  ) : null}
                  {ctaButtonNotice ? <DangerouslySetInnerHtml html={ctaButtonNotice} /> : null}
                </h3>
              ) : null}
            </div>

            {video?.length && video[0].properties.link ? (
              <VideoPlayer
                onlyVideo
                {...video[0].properties}
                preTitle={videoSettings?.preTitle}
                timeNames={{ sec: videoSettings?.secText, min: videoSettings?.minText }}
              />
            ) : null}
          </div>

          {image?.fallbackUrl ? (
            <div className="info-section__image">
              {image?.fallbackUrl ? (
                <GatsbyImage useFocalPoint fluid={image} alt={imageAlt} />
              ) : null}
              {video?.length && video[0].properties.url ? (
                <VideoBlock
                  link={video[0].properties.url}
                  name={video[0].properties.title}
                  time={video[0].properties.duration}
                  closeText={videoSettings?.closeModalText}
                  ariaLabel={video[0].properties.ariaLabel}
                  timeNames={{
                    min: videoSettings?.minText,
                    sec: videoSettings?.secText,
                  }}
                  preTitle={videoSettings?.preTitle}
                />
              ) : null}
            </div>
          ) : null}

          {videoInlineBlock?.length ? (
            <VideoBlockInline
              mpUrl={videoInlineBlock[0].properties.mpUrl}
              webmUrl={videoInlineBlock[0].properties.webmUrl}
              isAutoPlay={videoInlineBlock[0].properties.isAutoPlay}
              isLoop={videoInlineBlock[0].properties.isLoop}
              isMuted={videoInlineBlock[0].properties.isMuted}
            />
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentInfoSection on TInfoSectionStructure {
    structure
    properties {
      imageAlt
      imageAlign
      video {
        structure
        properties {
          title
          url
          link
          duration
          ariaLabel
          videoPreviewPosterAlt
          videoPreviewPoster {
            ...FragmentGatsbyImage
          }
          playButtonAria
        }
      }
      description
      title
      link {
        name
        url
      }
      ctaButtonType
      ariaCTA
      image {
        ...FragmentGatsbyImage
      }
      ctaButtonNotice
      sectionBackground {
        label
      }
    }
  }
`;

export default InfoSection;
