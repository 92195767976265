import React, { FC } from 'react'

import ControlledCarousel from 'gatsby-theme-gaviscon/src/components/ControlledCarousel'
import BannerContent from 'gatsby-theme-gaviscon/src/components/HomeBanner/BannerContent'

import useReducedMotion from 'gatsby-theme-gaviscon/src/hooks/useReducedMotion'
import { IHomeBannerSliderProps } from 'gatsby-theme-gaviscon/src/components/HomeBanner/HomeBannerSlider/model'
import responsiveObj from 'gatsby-theme-gaviscon/src/components/HomeBanner/HomeBannerSlider/constants'
import 'gatsby-theme-gaviscon/src/components/HomeBanner/HomeBannerSlider/HomeBannerSlider.scss'

const HomeBannerSlider: FC<IHomeBannerSliderProps> = ({ topBanner }) => {
  const isAnimationsReduced = useReducedMotion()

  return (
    <ControlledCarousel
      showDots
      swipeable
      infinite
      autoPlay={!isAnimationsReduced}
      autoPlaySpeed={5000}
      classes="home-banner__carousel"
      responsiveObj={responsiveObj}
    >
      {topBanner.map((item, idx) => {
        return (
          <BannerContent
            key={item.properties?.description}
            bannerContent={item.properties}
            showStickyLink={false}
            isMainBanner={idx === 0}
          />
        )
      })}
    </ControlledCarousel>
  )
}

export default HomeBannerSlider
