import React, { FC } from "react";
import DangerouslySetInnerHtml from "gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml";
import Button from "gatsby-theme-gaviscon/src/components/common/Button";
import IconCustom from "gatsby-theme-gaviscon/src/components/common/IconCustom";
import GatsbyImage from "gatsby-theme-gaviscon/src/components/common/GatsbyImage";
import useScreenRecognition from "gatsby-theme-gaviscon/src/hooks/useScreenRecognition";
import { BannerContentProps } from "./model";
import "./BannerContentBackground.scss";

const BannerContentBackground: FC<BannerContentProps> = ({
  bannerContent: {
    title,
    description,
    veevaNumber,
    homepageBannerDisclaimer,
    image,
    imageAlt,
    bgImage,
    bgImageMobile,
    productSlogan,
    productImage,
    productImageAlt,
    link: cta,
    linkVariant,
    bgColor,
  },
  isMainBanner = true,
}) => {
  const { isNotMobile, isMobile } = useScreenRecognition();
  const link = cta?.length ? cta[0] : null;
  const ctaPath = (link?.url || "").concat(link?.queryString || "");
  const variant = linkVariant?.[0] || "primary";

  const HeadingTag = isMainBanner ? "h1" : "h2";

  return (
    <div
      className={`home-banner home-banner__background home-banner--${bgColor}`}
      data-testid="home-banner"
    >
      {bgImage?.fallbackUrl ? (
        <div className="home-banner__image-desktop">
          <GatsbyImage
            isLazyLoading={!isMainBanner}
            className="home-banner__image-img"
            fluid={bgImage}
            objectPosition="50% 100%"
            useFocalPoint
            alt={"Gaviscon"}
          />

          {bgImageMobile?.fallbackUrl ? (
            <GatsbyImage
              isLazyLoading={!isMainBanner}
              className="home-banner__image-img home-banner__image-img-mobile"
              fluid={bgImageMobile}
              objectPosition="50% 100%"
              useFocalPoint
              alt={"Gaviscon"}
            />
          ) : null}
        </div>
      ) : null}

      <div className="home-banner__crop">
        <div className="home-banner__footer">
          {link ? (
            <Button to={ctaPath} variant={variant}>
              {link.name}
              <IconCustom icon="chevron-right" />
            </Button>
          ) : null}
          {homepageBannerDisclaimer ? (
            <DangerouslySetInnerHtml
              className="home-banner__footer-disclaimer disclaimer-banner-new"
              html={homepageBannerDisclaimer}
            />
          ) : null}
          {productImage?.fallbackUrl ? (
            <div className="home-banner__footer-image">
              {isMobile ? (
                <GatsbyImage
                  isLazyLoading={!isMainBanner}
                  objectFit="contain"
                  objectPosition="50% 100%"
                  className="home-banner__image-img"
                  fluid={productImage}
                  alt={productImageAlt}
                />
              ) : null}
            </div>
          ) : null}
        </div>

        {productImage?.fallbackUrl ? (
          <div className="home-banner__product">
            {isNotMobile ? (
              <GatsbyImage
                isLazyLoading={!isMainBanner}
                objectFit="contain"
                objectPosition="50% 100%"
                className="home-banner__image-img"
                fluid={productImage}
                alt={productImageAlt}
              />
            ) : null}
            {!isNotMobile ? (
              <GatsbyImage
                isLazyLoading={!isMainBanner}
                objectFit="contain"
                objectPosition="50% 100%"
                className="home-banner__image-img"
                fluid={productImage}
                alt={productImageAlt}
                fadeIn={false}
              />
            ) : null}
          </div>
        ) : null}

        <div className="crop-right-container">
          {image?.fallbackUrl ? (
            <div className="home-banner__image-desktop">
              <GatsbyImage
                isLazyLoading={!isMainBanner}
                className="home-banner__image-img"
                fluid={image}
                alt={imageAlt}
                objectPosition="50% 100%"
                useFocalPoint
              />
              {productSlogan ? (
                <DangerouslySetInnerHtml
                  className="home-banner__product-slogan"
                  html={productSlogan}
                />
              ) : null}
            </div>
          ) : null}

          <div className="home-banner__text">
            <HeadingTag
              className="home-banner__text-title"
              data-testid="home-banner__title"
            >
              {title.map(({ value }) => (
                <DangerouslySetInnerHtml
                  key={value}
                  element="span"
                  className="home-banner__text-line"
                  html={value}
                />
              ))}
            </HeadingTag>
            <article className="home-banner__inline-content">
              {description ? (
                <DangerouslySetInnerHtml
                  className="home-banner__text-description"
                  html={description}
                />
              ) : null}
              {link ? (
                <Button to={ctaPath} variant={variant}>
                  {link.name}
                  <IconCustom icon="chevron-right" />
                </Button>
              ) : null}
            </article>
            {homepageBannerDisclaimer ? (
              <DangerouslySetInnerHtml
                className="home-banner__text-disclaimer disclaimer-banner-new"
                html={homepageBannerDisclaimer}
              />
            ) : null}
          </div>
        </div>
        {veevaNumber ? (
          <DangerouslySetInnerHtml
            className="home-banner__veeva-number"
            html={veevaNumber}
          />
        ) : null}
      </div>
    </div>
  )
}

export default BannerContentBackground
