import React, { FC } from 'react';
import { Link } from 'react-scroll';

import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import Button from 'gatsby-theme-gaviscon/src/components/common/Button';
import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';

import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';
import { BannerContentProps } from './model';

const BannerContent: FC<BannerContentProps> = ({
  bannerContent: {
    title,
    description,
    homepageBannerDisclaimer,
    image,
    imageAlt,
    ribbonImage,
    ribbonImageAlt,
    productImage,
    productImageAlt,
    link: cta,
    linkVariant,
    ariaScroll,
    scrollAnchor,
    productSlogan,
    showRibbonOnMobile,
  },
  showStickyLink = true,
  isMainBanner = true,
}) => {
  const { isNotMobile, isMobile } = useScreenRecognition();
  const link = cta?.length ? cta[0] : null;
  const ctaPath = (link?.url || '').concat(link?.queryString || '');
  const variant = linkVariant?.[0] || 'primary';

  const HeadingTag = isMainBanner ? 'h1' : 'h2';
  const scrollToSection = scrollAnchor || 'main-content';

  return (
    <div className="home-banner" data-testid="home-banner">
      <div className="home-banner__crop">
        <div className="home-banner__text">
          <HeadingTag className="home-banner__text-title" data-testid="home-banner__title">
            {title.map(({ value }) => (
              <DangerouslySetInnerHtml
                key={value}
                element="span"
                className="home-banner__text-line"
                html={value}
              />
            ))}
          </HeadingTag>
          {description ? (
            <DangerouslySetInnerHtml className="home-banner__text-description" html={description} />
          ) : null}
          {link ? (
            <Button to={ctaPath} variant={variant}>
              {link.name}
              <IconCustom icon="chevron-right" />
            </Button>
          ) : null}
          {homepageBannerDisclaimer ? (
            <p className="home-banner__text-disclaimer">{homepageBannerDisclaimer}</p>
          ) : null}
        </div>
        {image?.fallbackUrl ? (
          <div className="home-banner__image-desktop">
            <GatsbyImage
              isLazyLoading={!isMainBanner}
              className="home-banner__image-img"
              fluid={image}
              alt={imageAlt}
              objectPosition="50% 100%"
              useFocalPoint
            />
            {productSlogan ? (
              <DangerouslySetInnerHtml
                className="home-banner__product-slogan"
                html={productSlogan}
              />
            ) : null}
          </div>
        ) : null}
        {!!Number(showRibbonOnMobile) || (isNotMobile && ribbonImage?.fallbackUrl) ? (
          <div className="home-banner__ribbon">
            <GatsbyImage
              style={{ position: 'absolute' }}
              isLazyLoading={!isMainBanner}
              objectFit="contain"
              objectPosition="50% 100%"
              fluid={ribbonImage}
              alt={ribbonImageAlt}
            />
          </div>
        ) : null}
        {productImage?.fallbackUrl ? (
          <div className="home-banner__product">
            {isNotMobile ? (
              <GatsbyImage
                isLazyLoading={!isMainBanner}
                objectFit="contain"
                objectPosition="50% 100%"
                className="home-banner__image-img"
                fluid={productImage}
                alt={productImageAlt}
              />
            ) : null}
            {isMobile ? (
              <GatsbyImage
                isLazyLoading={!isMainBanner}
                objectFit="contain"
                objectPosition="50% 100%"
                className="home-banner__image-img"
                fluid={productImage}
                alt={productImageAlt}
                fadeIn={false}
              />
            ) : null}
          </div>
        ) : null}
      </div>

      {showStickyLink ? (
        <div className="home-banner__sticky">
          <div className="home-banner__sticky-link">
            <Link
              to={scrollToSection}
              offset={-50}
              smooth
              duration={300}
              role="button"
              name={ariaScroll}
              aria-label={ariaScroll}
              tabIndex="0"
            >
              <IconCustom icon="arrow_down" />
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BannerContent;
