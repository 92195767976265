import React, { FC } from 'react';
import { IHomeBannerProps } from 'gatsby-theme-gaviscon/src/components/HomeBanner/model';
import HomeBannerSlider from './HomeBannerSlider';
import 'gatsby-theme-gaviscon/src/components/HomeBanner/HomeBanner.scss';
import './HomeBanner.scss';
import BannerContentBackground from './BannerContentBackground';
import BannerContent from 'gatsby-theme-gaviscon/src/components/HomeBanner/BannerContent'

const HomeBanner: FC<IHomeBannerProps> = ({ topBanner }) => {
  const isSliderType = topBanner?.length > 1

  return isSliderType ? (
    <HomeBannerSlider topBanner={topBanner} />
  ) : topBanner[0].structure === "Homepage Banner with background" ? (
    <BannerContentBackground bannerContent={topBanner[0].properties} />
  ) : (
    <BannerContent bannerContent={topBanner[0].properties} />
  )
}

export default HomeBanner
