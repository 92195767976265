import React, { FC } from 'react';

import { IVideoBlockInlineProperties } from './model';
import { graphql } from 'gatsby';

const VideoBlockInline: FC<IVideoBlockInlineProperties> = ({
  mpUrl,
  webmUrl,
  isAutoPlay,
  isLoop,
  isMuted,
}) => (
  <div className="video-block-inline">
    <video
      className="video-inline"
      autoPlay={isAutoPlay}
      loop={isLoop}
      muted={isMuted}
      playsInline
    >
      <source src={webmUrl} type="video/webm" />
      <source src={mpUrl} type="video/mp4" />
    </video>
  </div>
);

export const query = graphql`
  fragment FragmentVideoBlockInline on TVideoInlineStructure {
    properties {
      mpUrl
      webmUrl
      isAutoPlay
      isLoop
      isMuted
    }
  }
`;

export default VideoBlockInline;
